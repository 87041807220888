
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as redirect_45assistantBr8O2YbRemMeta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/redirect-assistant.vue?macro=true";
import { default as _91txId_938AJxxE6KR1Meta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/pending/[txId].vue?macro=true";
import { default as affiliateK5tsbtU9TYMeta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/affiliate.vue?macro=true";
import { default as ansvarligt_45spilVB5yz958hVMeta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/ansvarligt-spil.vue?macro=true";
import { default as redirect_45assistantAFPhKGIhH3Meta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/app/redirect-assistant.vue?macro=true";
import { default as bankozo25stvLIaMeta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/banko.vue?macro=true";
import { default as betingelserNuhGQUbqoXMeta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/betingelser.vue?macro=true";
import { default as bingo7F6f2CceEyMeta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/bingo.vue?macro=true";
import { default as BlogM3Bo3cpeXKMeta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/Blog.vue?macro=true";
import { default as bonuskodefragciABe0Meta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/bonuskode.vue?macro=true";
import { default as casinoA3TNIb5MzIMeta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/casino.vue?macro=true";
import { default as cookiepolitikZJb3Ry6Y7FMeta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/cookiepolitik.vue?macro=true";
import { default as faqyH3z9HOwgOMeta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/faq.vue?macro=true";
import { default as _91slug_93vm5OJK1n9OMeta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/games/entrance/[slug].vue?macro=true";
import { default as index1DUer56la8Meta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/index.vue?macro=true";
import { default as indexFKoVlLLdNmMeta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/konkurrence/index.vue?macro=true";
import { default as ansvarligt_45spilRhxVvbWgYzMeta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/konto/ansvarligt-spil.vue?macro=true";
import { default as beskederTalTzKRaKjMeta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/konto/beskeder.vue?macro=true";
import { default as biometrir01zeRJCaWMeta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/konto/biometri.vue?macro=true";
import { default as _91reportId_93Gd3W9e5PhaMeta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/konto/brugerrapport/[reportId].vue?macro=true";
import { default as eidxfwxnJTCfqMeta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/konto/eid.vue?macro=true";
import { default as indbetalingsloftyMpChNsPglMeta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/konto/indbetalingsloft.vue?macro=true";
import { default as nedkoelingbt1yjQscekMeta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/konto/nedkoeling.vue?macro=true";
import { default as sletninguSXU9FcmwBMeta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/konto/sletning.vue?macro=true";
import { default as spaering5ko6ev0BOBMeta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/konto/spaering.vue?macro=true";
import { default as kundeservicekYp5NeYU1WMeta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/kundeservice.vue?macro=true";
import { default as mobil_45appfdtyxgSgoIMeta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/mobil-app.vue?macro=true";
import { default as om_45osNv6Pl3Cv0eMeta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/om-os.vue?macro=true";
import { default as loginfbXnzutusgMeta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/playgame/login.vue?macro=true";
import { default as presseOEDIopIgGGMeta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/presse.vue?macro=true";
import { default as privatlivspolitikGy7bXfo5SxMeta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/privatlivspolitik.vue?macro=true";
import { default as signupgkjc2SwTWwMeta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/signup.vue?macro=true";
import { default as gemte_45spilHH6xT9MbzgMeta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/spilleautomater/gemte-spil.vue?macro=true";
import { default as indexdhrlOTZKGfMeta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/spilleautomater/index.vue?macro=true";
import { default as spiloversigt0V7tgJdk8UMeta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/spiloversigt.vue?macro=true";
import { default as _91seoName_935XKC75NoI7Meta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/spilside/[seoName].vue?macro=true";
import { default as tvRp1bcUMpfJMeta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/tv.vue?macro=true";
import { default as vindere0hU0fpx5QlMeta } from "/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/vindere.vue?macro=true";
export default [
  {
    name: "redirect-assistant",
    path: "/payments/redirect-assistant",
    meta: redirect_45assistantBr8O2YbRemMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/redirect-assistant.vue")
  },
  {
    name: "payments-pending",
    path: "/payments/pending/:txId",
    meta: _91txId_938AJxxE6KR1Meta || {},
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/pending/[txId].vue")
  },
  {
    name: "affiliate",
    path: "/affiliate",
    meta: affiliateK5tsbtU9TYMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/affiliate.vue")
  },
  {
    name: "ansvarligt-spil",
    path: "/ansvarligt-spil",
    meta: ansvarligt_45spilVB5yz958hVMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/ansvarligt-spil.vue")
  },
  {
    name: "app-redirect-assistant",
    path: "/app/redirect-assistant",
    meta: redirect_45assistantAFPhKGIhH3Meta || {},
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/app/redirect-assistant.vue")
  },
  {
    name: "banko",
    path: "/banko",
    meta: bankozo25stvLIaMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/banko.vue")
  },
  {
    name: "betingelser",
    path: "/betingelser",
    meta: betingelserNuhGQUbqoXMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/betingelser.vue")
  },
  {
    name: "bingo",
    path: "/bingo",
    meta: bingo7F6f2CceEyMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/bingo.vue")
  },
  {
    name: "Blog",
    path: "/Blog",
    meta: BlogM3Bo3cpeXKMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/Blog.vue")
  },
  {
    name: "bonuskode",
    path: "/bonuskode",
    meta: bonuskodefragciABe0Meta || {},
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/bonuskode.vue")
  },
  {
    name: "casino",
    path: "/casino",
    meta: casinoA3TNIb5MzIMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/casino.vue")
  },
  {
    name: "cookiepolitik",
    path: "/cookiepolitik",
    meta: cookiepolitikZJb3Ry6Y7FMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/cookiepolitik.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqyH3z9HOwgOMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/faq.vue")
  },
  {
    name: "games-entrance-slug",
    path: "/games/entrance/:slug()",
    meta: _91slug_93vm5OJK1n9OMeta || {},
    alias: ["/spil/:slug","/spilleautomater/entrance/:slug"],
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/games/entrance/[slug].vue")
  },
  {
    name: "index",
    path: "/",
    meta: index1DUer56la8Meta || {},
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/index.vue")
  },
  {
    name: "konkurrence",
    path: "/konkurrence",
    meta: indexFKoVlLLdNmMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/konkurrence/index.vue")
  },
  {
    name: "konto-ansvarligt-spil",
    path: "/konto/ansvarligt-spil",
    meta: ansvarligt_45spilRhxVvbWgYzMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/konto/ansvarligt-spil.vue")
  },
  {
    name: "konto-beskeder",
    path: "/konto/beskeder",
    meta: beskederTalTzKRaKjMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/konto/beskeder.vue")
  },
  {
    name: "konto-biometri",
    path: "/konto/biometri",
    meta: biometrir01zeRJCaWMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/konto/biometri.vue")
  },
  {
    name: "konto-brugerrapport-reportId",
    path: "/konto/brugerrapport/:reportId()",
    meta: _91reportId_93Gd3W9e5PhaMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/konto/brugerrapport/[reportId].vue")
  },
  {
    name: "konto-eid",
    path: "/konto/eid",
    meta: eidxfwxnJTCfqMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/konto/eid.vue")
  },
  {
    name: "konto-indbetalingsloft",
    path: "/konto/indbetalingsloft",
    meta: indbetalingsloftyMpChNsPglMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/konto/indbetalingsloft.vue")
  },
  {
    name: "konto-nedkoeling",
    path: "/konto/nedkoeling",
    meta: nedkoelingbt1yjQscekMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/konto/nedkoeling.vue")
  },
  {
    name: "konto-sletning",
    path: "/konto/sletning",
    meta: sletninguSXU9FcmwBMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/konto/sletning.vue")
  },
  {
    name: "konto-spaering",
    path: "/konto/spaering",
    meta: spaering5ko6ev0BOBMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/konto/spaering.vue")
  },
  {
    name: "kundeservice",
    path: "/kundeservice",
    meta: kundeservicekYp5NeYU1WMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/kundeservice.vue")
  },
  {
    name: "mobil-app",
    path: "/mobil-app",
    meta: mobil_45appfdtyxgSgoIMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/mobil-app.vue")
  },
  {
    name: "om-os",
    path: "/om-os",
    meta: om_45osNv6Pl3Cv0eMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/om-os.vue")
  },
  {
    name: "playgame-login",
    path: "/playgame/login",
    meta: loginfbXnzutusgMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/playgame/login.vue")
  },
  {
    name: "presse",
    path: "/presse",
    meta: presseOEDIopIgGGMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/presse.vue")
  },
  {
    name: "privatlivspolitik",
    path: "/privatlivspolitik",
    meta: privatlivspolitikGy7bXfo5SxMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/privatlivspolitik.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupgkjc2SwTWwMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/signup.vue")
  },
  {
    name: "spilleautomater-gemte-spil",
    path: "/spilleautomater/gemte-spil",
    meta: gemte_45spilHH6xT9MbzgMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/spilleautomater/gemte-spil.vue")
  },
  {
    name: "spilleautomater",
    path: "/spilleautomater",
    meta: indexdhrlOTZKGfMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/spilleautomater/index.vue")
  },
  {
    name: "spiloversigt",
    path: "/spiloversigt",
    meta: spiloversigt0V7tgJdk8UMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/spiloversigt.vue")
  },
  {
    name: "spilside-seoName",
    path: "/spilside/:seoName()",
    meta: _91seoName_935XKC75NoI7Meta || {},
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/spilside/[seoName].vue")
  },
  {
    name: "tv",
    path: "/tv",
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/tv.vue")
  },
  {
    name: "vindere",
    path: "/vindere",
    meta: vindere0hU0fpx5QlMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_mbtSuh/1/spilnu/frontend/spilnu/pages/vindere.vue")
  }
]